import React from "react";

import { LicensableProperty } from "../model/licensable_property";
import LicensablePropertyCard from "./licensable_property_card";
import { redirect, useNavigate } from "react-router-dom";

interface LicensablePropertyListProps {
  licensableProperties: LicensableProperty[];
  onItemSelected: (licensableProperty: LicensableProperty) => void;
  showSocialAccounts: boolean;
}

const LicensablePropertyList = ({ licensableProperties, onItemSelected, showSocialAccounts }: LicensablePropertyListProps) => {

  if (licensableProperties.length === 0) {
    return <div className="text-white flex h-full border border-white">No properties found</div>;
  }

  return (
    <div className="grid_list_container flex flex-wrap gap-0">
      {licensableProperties.map((licensableProperty) => (
        <LicensablePropertyCard
          key={licensableProperty.id}
          showSocialAccounts={showSocialAccounts}
          licensableProperty={licensableProperty}
          onClick={() => onItemSelected(licensableProperty)}
        />
      ))}
    </div>
  );
};
export default LicensablePropertyList;
