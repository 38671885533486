import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLicensablePropertyAPI } from "../../api/licensable_property_api";
import { LicensableProperty } from "../../model/licensable_property";
import Heading from "../../common/components/heading";
import { useAppSelector } from "../../hooks";
import { LPHeading } from "./components/licensable_property_heading";
import { LPFAQ } from "./components/licensable_property_faq";
import { LPCommercialTerms } from "./components/licensable_property_commecial_terms";
import { LPPricing } from "./components/licensable_property_pricing";
import { LPActions } from "./components/licensable_property_actions";
import { LPContentTopics } from "./components/licensable_property_content_topics";
import { LPDescription } from "./components/licensable_property_description";
import { useToast } from "../../common/components/ui/use_toast";
import { Button } from "../../common/components/ui/button";

export const LicensablePropertyProfile = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { id } = useParams<{ id: string }>();
  const { profile } = useAppSelector((state) => state.user);
  const LPApi = useLicensablePropertyAPI();
  const [licensableProperty, setLicensableProperty] = useState<LicensableProperty | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [licenseType, setLicenseType] = useState<"commercial" | "personal" | "expanded">("commercial");

  useEffect(() => {
    try {
      if (id && LPApi && !licensableProperty) {
        LPApi.getById(id).then((lp) => {
          setLicensableProperty(lp);
        });
      }
    } catch (error) {
      console.log("err", error);
    }
  }, [id, LPApi, licensableProperty, setLicensableProperty]);

  const handlePublish = () => {
    setIsLoading(true);
    try {
      LPApi.updateLicensableProperty({ ...licensableProperty, is_active: true }).then((lp) => {
        setLicensableProperty(lp);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      console.log("err", error);
    }
  };

  const handleEdit = () => {
    if (licensableProperty) {
      navigate(`/lp/${licensableProperty.id}/edit`);
    }
  };

  const handleDelete = async () => {
    if (licensableProperty) {
      const lpName = licensableProperty.name;
      // TODO need to handled failed requests
      const res = await LPApi.deleteLicensableProperty(licensableProperty);
      toast({
        title: "Success!",
        description: `Profile for ${lpName} deleted.`,
      });
      navigate("/dashboard");
    }
  };

  if (!licensableProperty) {
    return (
      <div
        className="bg-black text-gray-700 flex w-full h-100vh justify-center items-center"
        style={{ height: "100vh" }}
      >
        <Heading as="h3">Loading...</Heading>
      </div>
    );
  }

  return (
    <>
      <div className="relative isolate overflow-hidden">
        {licensableProperty.hero_image && (
          <img
            src={licensableProperty.hero_image}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
        )}
        <div className="mx-auto py-32 sm:py-48 lg:py-64 flex justify-center items-center">
          {!licensableProperty.hero_image && (
            <Button size="xs" variant="outline" onClick={() => handleEdit()}>
              Upload image
            </Button>
          )}
        </div>
      </div>
      <div className="mx-auto sm:max-w-7xl max-w-6xl flex-grow sm:px-8 lg:px-8 px-4">
        <div className="grid grid-cols-2 gap-10 pt-20">
          <div className="text-white flex flex-col gap-20">
            <LPHeading licensableProperty={licensableProperty} />
            <LPDescription licensableProperty={licensableProperty} />
            <LPFAQ licensableProperty={licensableProperty} />
            <LPCommercialTerms licensableProperty={licensableProperty} licenseType={licenseType} />
          </div>
          <div className="bg-gray-900 flex flex-col gap-4 p-10 pt-14 rounded-3xl text-white">
            <LPActions
              isLoading={isLoading}
              profile={profile}
              handlePublish={handlePublish}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              licensableProperty={licensableProperty}
            />
            <LPPricing licensableProperty={licensableProperty} onLicenseTypeChange={setLicenseType} />
            <LPContentTopics licensableProperty={licensableProperty} />
            <div className="flex flex-col gap-4 text-center sm:my-10">
              <Heading as="h5" className="text-center font-sans not-italic font-semibold">
                Training data
              </Heading>
              <p className="text-xs">No training data available at this time</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicensablePropertyProfile;
