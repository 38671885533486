import React from "react";
import PublicHeader from "../layouts/public_header";
import "./how_it_works.css";
import "./home.css";
import AppFooter from "../layouts/app_footer";
const HowItWorks = () => {
    return <main className="page_container home_page">
        <PublicHeader theme="dark"/>
        <div className="how_works_section_1 pixel_yellow text-blackish pt-[100px] text-center">
            <div className="text_block">
                <div className="pill_button text-m-sans">For Talent</div>
                <h1 className="text-xxxxl-serif">How it works</h1>
                <p className="text-xl-sans">Monetize your likeness in AI content effortlessly. Set usage rights, earn
                    passive income, and maintain control with Official AI&apos;s seamless licensing platform</p>
            </div>
            <div className="pixel_block">
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_blue"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_red"/>
                <div className="pixel_sm pixel_pink"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_blue"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_white"/>
                <div className="pixel_sm pixel_white"/>
                <div className="pixel_sm pixel_black"/>
            </div>
        </div>
        <div className="how_works_section_2">
            <div className="bullet_number">1</div>
            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Set your AI licensing standards</h2>
                <p className="text-l-sans text-normal">In the age of AI-generated content, safeguarding your brand and
                    values is crucial. With Official AI, you establish the standards for licensing your likeness. Craft
                    a detailed bio that outlines your preferred commercial genres, project themes, and pricing. By
                    setting clear licensing guidelines, you ensure that your AI presence aligns with your beliefs and
                    public image.</p>
            </div>
            <div className="image_block">
                <div className="background_pixel pixel_pink"/>
                <img src={require("./images/for_talent_img_1.png")} alt="For talent pricing" width="317" height="688"/>
            </div>
        </div>
        <div className="how_works_section_3">
            <div className="image_block">
                <div className="background_pixel pixel_blue"/>
                <img src={require("./images/for_talent_img_2.png")} alt="For talent pricing" width="317" height="688"/>
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">License your likeness effortlessly</h2>
                <p className="text-l-sans text-normal">Sit back and let Official AI handle the licensing process for
                    you. Our platform connects you with brands and content creators seeking to license authentic
                    likenesses for their AI-generated projects. You&apos;ll receive licensing requests and have the
                    power to approve or decline each one. Once you&apos;ve approved a license you&apos;ll earn 75% of
                    the licensing fee with Official AI retaining 25%.</p>
            </div>
            <div className="bullet_number">2</div>

        </div>
        <div className="how_works_section_4">
            <div className="bullet_number">3</div>
            <div className="text_block mr-[32px]">
                <h2 className="text-xxl-serif">Monitor your licenses &amp; earnings</h2>
                <p className="text-l-sans text-normal">Keep track of your AI identity&apos;s usage and earnings through your Official AI dashboard.
                    Our advanced analytics show you where and how your likeness is being used for each license granted.
                    With Official AI, you can monetize your unique likeness while maintaining control and transparency.</p>
            </div>
            <div className="image_block">
                <img src={require("./images/for_talent_img_3.png")} alt="For talent pricing" width="317" height="688"/>
                <div className="background_pixel pixel_red"/>

            </div>
        </div>
        <div className="how_works_section_5">
            <div className="text_block text-xxl-sans">We&apos;re igniting the next creative renaissance with infrastructure
                to power authenticity.</div>
            <div>
            <a href="/register" className="pill_button text-m-sans text-nowrap">Get started</a>
            </div>
        </div>

        <AppFooter/>
    </main>
}

export default HowItWorks;