import React from "react";
import PublicHeader from "../layouts/public_header";
import "./how_it_works.css";
import "./home.css";
import AppFooter from "../layouts/app_footer";
const HowItWorksMarketers = () => {
    return <main className="page_container home_page">
        <PublicHeader theme="dark"/>
        <div className="how_works_section_1 pixel_yellow text-blackish pt-[100px] text-center">
            <div className="text_block">
                <div className="pill_button text-m-sans">For Marketers </div>
                <h1 className="text-xxxxl-serif">How it works</h1>
                <p className="text-xl-sans">Create authentic, celebrity-driven generative AI content while saving time, money, and mitigating legal risks.</p>
            </div>
            <div className="pixel_block">
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_blue"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_red"/>
                <div className="pixel_sm pixel_pink"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_blue"/>
                <div className="pixel_sm pixel_transparent"/>
                <div className="pixel_sm pixel_white"/>
                <div className="pixel_sm pixel_white"/>
                <div className="pixel_sm pixel_black"/>
            </div>
        </div>
        <div className="how_works_section_2">
            <div className="bullet_number">1</div>
            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Discover Authentic Likenesses for Your AI Content</h2>
                <p className="text-l-sans text-normal">Find the perfect talent to bring authenticity to your AI-generated content.
                    Official AI&apos;s platform offers a vast catalog of licensed likenesses from actors,
                    athletes, influencers, and more. Search for talent that aligns with your brand and project
                    requirements. Preview their AI identities and usage terms to ensure a seamless fit.</p>
            </div>
            <div className="image_block">
                <div className="background_pixel pixel_pink"/>
                <img src={require("./images/for_marketers_img_1.png")} alt="For talent pricing" width="317" height="688"/>
            </div>
        </div>
        <div className="how_works_section_3">
            <div className="image_block">
                <div className="background_pixel pixel_blue"/>
                <img src={require("./images/for_marketers_img_2.png")} alt="For talent pricing" width="317" height="688"/>
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">License talent seamlessly</h2>
                <p className="text-l-sans text-normal">Streamline your licensing process with Official AI. Once you&apos;ve found the ideal
                    talent for your AI content, simply submit a licensing request through our platform.
                    The talent will review and approve the request based on their predefined terms. Upon approval,
                    you&apos;ll gain access to their authenticated AI assets, ready to be integrated into your AI content generation pipeline.</p>
            </div>
            <div className="bullet_number">2</div>

        </div>
        <div className="how_works_section_4">
            <div className="bullet_number">3</div>
            <div className="text_block mr-[32px]">
                <h2 className="text-xxl-serif">Generate authenticated AI content</h2>
                <p className="text-l-sans text-normal">
                    Create AI-generated content featuring licensed talent likenesses with confidence. Our provenance technology
                    ensures each piece of content is properly authenticated and linked to the licensed talent.
                    This provides transparency for your audience and protects your brand from potential legal issues.
                    With Official AI, you can harness the power of AI-generated content while respecting talent rights and mitigating risk.</p>
            </div>
            <div className="image_block">
                <img src={require("./images/for_marketers_img_3.png")} alt="For talent pricing" width="317" height="688"/>
                <div className="background_pixel pixel_red"/>

            </div>
        </div>
        <div className="how_works_section_5">
            <div className="text_block text-xxl-sans">We&apos;re igniting the next creative renaissance with infrastructure
                to power authenticity.</div>
            <div>
            <a href="/register" className="pill_button text-m-sans text-nowrap">Get started</a>
            </div>
        </div>

        <AppFooter/>
    </main>
}

export default HowItWorksMarketers;