import React, { useState } from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormControl, FormField, FormItem, FormLabel } from "../../../common/components/ui/form";
import { Errors } from "./errors";
import { FIELDS } from "../../../lib/fields";
import { Input } from "../../../common/components/ui/input";
import { formatCurrency, numberWithCommas } from "../../../lib/utils";
import { Button } from "../../../common/components/ui/button";
import { CheckIcon, InfoIcon } from "lucide-react";
import { PencilIcon } from "../../../common/icons/icons";
import Heading from "../../../common/components/heading";
import { Popover, PopoverContent, PopoverTrigger } from "../../../common/components/ui/popover";

export const ArtistPricingStep = ({ form, errors }: { form: any; errors: any }) => {
  const [showPriceInputs, setShowPriceInputs] = useState<any>(
    FIELDS.LICENSE_TYPE.options.map((option: any) => {
      return { [option.value]: false };
    })
  );

  const getLPLicenseByType = (licenseType: string) => {
    const lpPrices = form.getValues("prices");
    return lpPrices.find((p: any) => p.license_type === licenseType);
  };

  const getLPLicensePrice = (licenseType: string) => {
    const license = getLPLicenseByType(licenseType);
    if (license?.price) {
      return license.price;
    }
    return 0;
  };

  const handleSetLicensePriceState = (license: any) => {
    const lpl = getLPLicenseByType(license.value);
    const _price = Number(lpl.price.replace(",", "")); // Strip commas and convert ot number
    const price = _price * 100;
    const idx = valueIdxOfLicenseType(license);
    form.setValue(`prices.${idx}.price`, price); // x100 for cents
    setShowPriceInputs({
      ...showPriceInputs,
      [license.value]: !showPriceInputs[license.value],
    });
  };

  const handleModifyLicensePriceState = (field: string, license: any) => {
    const lpl = getLPLicenseByType(license.value);
    const price = String((lpl.price / 100).toLocaleString()); // Add commas back
    const idx = valueIdxOfLicenseType(license);
    form.setValue(`prices.${idx}.price`, price);
    setShowPriceInputs({
      ...showPriceInputs,
      [license.value]: !showPriceInputs[license.value],
    });
  };

  // Because the array order of form field values is random,
  // we need to always find the correct idx that corresponds w/ license type value
  const valueIdxOfLicenseType = (license: any) => {
    const lpPrices = form.getValues("prices");
    return lpPrices.findIndex((p: any) => p.license_type === license.value);
  };

  return (
    <WizardStep>
      <div className={`grid grid-cols-${FIELDS.LICENSE_TYPE.options.length} gap-10 mb-10`}>
        {FIELDS.LICENSE_TYPE.options.map((license: any) => {
          // WARNING: map index doesn't always match the form field value indexes; very unreliable
          const idx = valueIdxOfLicenseType(license);
          return (
            <div key={`${license.value}-${idx}`} className="bg-gray-900 text-white text-center p-10 rounded-xl">
              <Heading as="h3">{license.label}</Heading>
              <p>{license.description}</p>
              <FormField
                control={form.control}
                name={`prices.${idx}.price`}
                render={() => (
                  <FormItem className="w-full mt-6 text-center">
                    <FormControl>
                      <div className="space-y-0">
                        <FormLabel className="text-xl flex justify-center gap-2 items-center">
                          Set your minium price
                          <Popover>
                            <PopoverTrigger>
                              <InfoIcon className="fill-brand-yellow" stroke="black" />
                            </PopoverTrigger>
                            <PopoverContent className="text-base bg-brand-yellow border-none">
                              This is helpful information about pricing for artists and what it means. If we need
                              something larger then that is fine.
                            </PopoverContent>
                          </Popover>
                        </FormLabel>
                        {showPriceInputs[license.value] === true ? (
                          <div className="flex justify-center items-center gap-4">
                            <div className="text-5xl text-white italic font-serif" style={{ marginRight: "-13px" }}>
                              $
                            </div>
                            <Input
                              {...form.register(`prices.${idx}.price`)}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  handleSetLicensePriceState(license);
                                }
                              }}
                              onBlur={(e) => handleSetLicensePriceState(license)}
                              onChange={(e) => form.setValue(`prices.${idx}.price`, numberWithCommas(e.target.value))}
                              className="text-xl max-w-96 italic sm:text-5xl p-0 font-serif w-64"
                            />
                            <Button
                              variant="icon"
                              className="h-14 w-14 p-0 rounded-full"
                              onClick={() => handleSetLicensePriceState(license)}
                            >
                              <CheckIcon height="16" />
                            </Button>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center gap-4">
                            <div className="text-5xl italic text-white font-serif">
                              {getLPLicensePrice(license.value) && formatCurrency(getLPLicensePrice(license.value))}
                            </div>
                            <Button
                              variant="icon"
                              className="h-14 w-14 p-0 rounded-full"
                              onClick={() => handleModifyLicensePriceState(`prices.${idx}.price`, license)}
                            >
                              <PencilIcon />
                            </Button>
                          </div>
                        )}
                        <div>Suggested price</div>
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-10 mb-10">
        <Errors errors={errors} />
        <p className="text-white">
          A Commercial Web License does not cover teams, online games, apps, VOD or Media Created for use by Enterprise
          Clients (over 50 employees). If you need any of these rights, please select Over 50 employees or Expanded
          Rights customers will speak with a representative and submit a bid to you.
        </p>
      </div>
    </WizardStep>
  );
};
