import React, {useMemo} from "react";
import {UseFormReturn} from "react-hook-form";
import {z, ZodIssue} from "zod";
import {LicensableProperty} from "../../../model/licensable_property";
import WizardPanel from "../../../common/components/wizard_panel";
import {Loader2} from "lucide-react";
import {projectSchema, PanelHeader} from "../project_wizard";
import CheckoutSidePanel from "./checkout_side_panel";

const CreditCardCheckout = ({
                          form,
                          licensableProperty,
                          submitForApproval,
                          errors,
                          submitting,
                          navigateToPanel,
                      }: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    licensableProperty: LicensableProperty | null;
    submitForApproval: () => void;
    errors: ZodIssue[];
    submitting: boolean;
    navigateToPanel: (panelNum: number) => void;
}) => {
    const allFields = form.watch();

    const inputClasses =
        "block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-gray-900 focus:outline-none focus:ring-0 focus:border-gray-900 peer";
    const labelClasses =
        "absolute text-sm text-gray-900 font-serif italic dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-gray-900 peer-focus:dark:text-gray-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto";
    return (
        <WizardPanel>
            <PanelHeader stepName="Payment" />
            <div className="flex flex-row gap-8">
                <div className="flex flex-col gap-8 mb-8 flex-grow">
                    {(allFields.files == null || allFields.files.length === 0) &&
                        <div className="alert warning" role="alert">
                            <div style={{ display: "flex", width:24, height:24,justifyContent:"center", alignItems: "center", borderRadius:"50px",background:"#161618" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                    <line x1="0.353553" y1="1.14645" x2="9.35355" y2="10.1464" stroke="white"/>
                                    <line x1="0.646447" y1="10.1464" x2="9.64645" y2="1.14645" stroke="white"/>
                                </svg>
                            </div>
                            <div className="text-s-sans flex-grow text-normal">Please upload at least one file for review to checkout</div>
                            <div onClick={() => navigateToPanel(4)} className="cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12.3265 4.57071C12.5343 4.36305 12.816 4.24639 13.1097 4.24639C13.4034 4.24639 13.6851 4.36305 13.8928 4.57071L20.539 11.2169C20.7466 11.4246 20.8633 11.7063 20.8633 12C20.8633 12.2937 20.7466 12.5754 20.539 12.7831L13.8928 19.4293C13.6839 19.6311 13.4041 19.7427 13.1137 19.7402C12.8232 19.7377 12.5454 19.6212 12.34 19.4158C12.1347 19.2104 12.0182 18.9326 12.0156 18.6422C12.0131 18.3517 12.1248 18.0719 12.3265 17.863L17.0819 13.1077L4.24814 13.1077C3.95436 13.1077 3.67261 12.991 3.46488 12.7833C3.25715 12.5755 3.14044 12.2938 3.14044 12C3.14044 11.7062 3.25715 11.4245 3.46488 11.2167C3.67261 11.009 3.95436 10.8923 4.24814 10.8923L17.0819 10.8923L12.3265 6.13698C12.1189 5.92926 12.0022 5.64757 12.0022 5.35385C12.0022 5.06013 12.1189 4.77843 12.3265 4.57071Z"
                                          fill="#161618"/>
                                </svg>
                            </div>
                        </div>
                    }
                    <div className="text-m-sans">Billing address</div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            id="billing_name"
                            {...form.register("billing_name")}
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label htmlFor="billing_name" className={labelClasses}>
                            Name
                        </label>
                        {errors &&
                            errors
                                .filter((it) => it.path[0] === "billing_name")
                                .map((it, idx) => (
                                    <div key={idx} className="text-red-500">
                                        {it.message}
                                    </div>
                                ))}
                    </div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            id="billing_address"
                            {...form.register("billing_address")}
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label htmlFor="billing_address" className={labelClasses}>
                            Address #1
                        </label>
                        {errors &&
                            errors
                                .filter((it) => it.path[0] === "billing_address")
                                .map((it, idx) => (
                                    <div key={idx} className="text-red-500">
                                        {it.message}
                                    </div>
                                ))}
                    </div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            id="billing_address2"
                            {...form.register("billing_address2")}
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label htmlFor="billing_address2" className={labelClasses}>
                            Address #2
                        </label>
                        {errors &&
                            errors
                                .filter((it) => it.path[0] === "billing_address2")
                                .map((it, idx) => (
                                    <div key={idx} className="text-red-500">
                                        {it.message}
                                    </div>
                                ))}
                    </div>
                    <div className="flex flex-row gap-2 w-full">
                        <div className="relative z-0 flex-grow">
                            <input
                                type="text"
                                id="billing_city"
                                {...form.register("billing_city")}
                                className={inputClasses}
                                placeholder=" "
                            />
                            <label htmlFor="billing_city" className={labelClasses}>
                                City
                            </label>
                            {errors &&
                                errors
                                    .filter((it) => it.path[0] === "billing_city")
                                    .map((it, idx) => (
                                        <div key={idx} className="text-red-500">
                                            {it.message}
                                        </div>
                                    ))}
                        </div>
                        <div className="relative z-0 flex-grow">
                            <select id="billing_state" {...form.register("billing_state")} className={inputClasses}>
                                <option value="">-- Choose --</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <label htmlFor="billing_state flex-grow" className={labelClasses}>
                                State
                            </label>
                            {errors &&
                                errors
                                    .filter((it) => it.path[0] === "billing_state")
                                    .map((it, idx) => (
                                        <div key={idx} className="text-red-500">
                                            {it.message}
                                        </div>
                                    ))}
                        </div>
                        <div className="relative z-0 flex-grow">
                            <input
                                type="text"
                                id="billing_zip"
                                {...form.register("billing_zip")}
                                className={inputClasses}
                                placeholder=" "
                            />
                            <label htmlFor="billing_zip" className={labelClasses}>
                                Zip code
                            </label>
                            {errors &&
                                errors
                                    .filter((it) => it.path[0] === "billing_zip")
                                    .map((it, idx) => (
                                        <div key={idx} className="text-red-500">
                                            {it.message}
                                        </div>
                                    ))}
                        </div>
                    </div>
                    <div className="text-m-sans">Billing information</div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            id="billing_card_name"
                            {...form.register("billing_card_name")}
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label htmlFor="billing_name_on_card" className={labelClasses}>
                            Name on card
                        </label>
                        {errors &&
                            errors
                                .filter((it) => it.path[0] === "billing_card_name")
                                .map((it, idx) => (
                                    <div key={idx} className="text-red-500">
                                        {it.message}
                                    </div>
                                ))}
                    </div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            id="billing_card_number"
                            {...form.register("billing_card_number")}
                            className={inputClasses}
                            placeholder=" "
                        />
                        <label htmlFor="billing_card_number" className={labelClasses}>
                            Card number
                        </label>
                        {errors &&
                            errors
                                .filter((it) => it.path[0] === "billing_card_number")
                                .map((it, idx) => (
                                    <div key={idx} className="text-red-500">
                                        {it.message}
                                    </div>
                                ))}
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="relative z-0 flex-grow">
                            <input
                                type="text"
                                id="billing_card_exp"
                                {...form.register("billing_card_exp")}
                                className={inputClasses}
                                placeholder=" "
                            />
                            <label htmlFor="billing_card_exp" className={labelClasses}>
                                Expiry date (MM/YY)
                            </label>
                            {errors &&
                                errors
                                    .filter((it) => it.path[0] === "billing_card_exp")
                                    .map((it, idx) => (
                                        <div key={idx} className="text-red-500">
                                            {it.message}
                                        </div>
                                    ))}
                        </div>
                        <div className="relative z-0 flex-grow">
                            <input
                                type="text"
                                id="billing_card_cvv"
                                {...form.register("billing_card_cvv")}
                                className={inputClasses}
                                placeholder=" "
                            />
                            <label htmlFor="billing_card_cvv" className={labelClasses}>
                                CVV
                            </label>
                            {errors &&
                                errors
                                    .filter((it) => it.path[0] === "billing_card_cvv")
                                    .map((it, idx) => (
                                        <div key={idx} className="text-red-500">
                                            {it.message}
                                        </div>
                                    ))}
                        </div>
                    </div>
                </div>
                <CheckoutSidePanel navigateToPanel={navigateToPanel}
                                   submitForApproval={submitForApproval}
                                   errors={errors}
                                   submitting={submitting}
                                   form={form}
                                   licensableProperty={licensableProperty}/>
            </div>
        </WizardPanel>
    );
};
export default CreditCardCheckout;