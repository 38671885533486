import React from "react"
import {UploadedFile} from "../../../api/file_api";
import FileCard from "./file_card";
import {ArrowRight} from "lucide-react";
import cn from "../../../lib/utils";

interface FileListingProps{
    files?: UploadedFile[];
    onRemove?: (file: UploadedFile) => void;
    canRemove: boolean;
    onAdd?: () => void;
    canAdd: boolean;
    theme?: "lite"|"dark"
}
const FileListing = ({files, canRemove, onRemove, canAdd, onAdd, theme}: FileListingProps) => {
    return <div className={cn("file-listing flex flex-col", (theme?"file-listing-"+theme:""))} style={{gap:"8px", maxWidth:"800px"}}>
        {files?.map((file) => {
            return <FileCard key={file.id || file.name} file={file} onRemove={onRemove} canRemove={canRemove} theme={theme}/>
        })}
        {canAdd &&
            <button type="button" style={{
                width:"100%",fontWeight: "bold",
                padding:"16px 30px",justifyContent:"center",
                alignItems:"center",gap:"10px",display:"flex",
                color: (theme==="dark"?"#161618":"#fff"),
                background:(theme==="dark"?"#fff":"#161618"),borderRadius:"70px"}}
                    onClick={onAdd}>
                <span>Add more assets</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="inline"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.293 3.29303C10.4805 3.10556 10.7348 3.00024 11 3.00024C11.2652 3.00024 11.5195 3.10556 11.707 3.29303L17.707 9.29303C17.8945 9.48056 17.9998 9.73487 17.9998 10C17.9998 10.2652 17.8945 10.5195 17.707 10.707L11.707 16.707C11.5184 16.8892 11.2658 16.99 11.0036 16.9877C10.7414 16.9854 10.4906 16.8803 10.3052 16.6948C10.1198 16.5094 10.0146 16.2586 10.0123 15.9964C10.01 15.7342 10.1108 15.4816 10.293 15.293L14.586 11H3C2.73478 11 2.48043 10.8947 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10C2 9.73481 2.10536 9.48046 2.29289 9.29292C2.48043 9.10539 2.73478 9.00003 3 9.00003H14.586L10.293 4.70703C10.1055 4.5195 10.0002 4.26519 10.0002 4.00003C10.0002 3.73487 10.1055 3.48056 10.293 3.29303Z"
                        fill="currentColor"
                    />
                </svg>
            </button>}
    </div>
}

export default FileListing;