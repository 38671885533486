import React, {useEffect} from "react";
import {createBrowserRouter, RouterProvider, useNavigate} from "react-router-dom";

import LicenseCheckRoot from "../pages/license-check/license_check_root";
import Dashboard from "../pages/dashboard/dashboard";
import Register from "../pages/register/register";
import LicensablePropertyProfile from "../pages/licensable-property/licensable_property_profile";
import AppContainer from "./app_container";
import Root from "../pages/root";
import ProjectWizard from "../pages/project/project_wizard";
import ProjectsRouter from "../pages/project/index";
import ArtistWizard from "../pages/licensable-property/artist_wizard";
import Project from "../pages/project/project";
import ProjectAssetReview from "../pages/project/project_asset_review";
import ProjectDescription from "../pages/project/project_description";
import Terms from "../pages/static/terms";
import Privacy from "../pages/static/privacy";
import RegisterComplete from "../pages/register/register_complete";
import AppHeader from "../pages/layouts/header";
import VerifyContent from "../pages/verify/verify_content";
import LicenseTerms from "../pages/licensable-property/license_terms";
import AppFooter from "../pages/layouts/app_footer";
import HowItWorks from "../pages/home/how_it_works";
import HowItWorksMarketers from "../pages/home/how_it_works_marketers";
import {setUserProfile} from "../user/userSlice";
import {useAuth0} from "@auth0/auth0-react";
import {useUserAPI} from "../api/user.api";
import {useAppDispatch, useAppSelector} from "../hooks";
import PublicHeader from "../pages/layouts/public_header";

function PageRouter() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { fetchUserProfile } = useUserAPI();
  const { profile, status } = useAppSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function checkProfile() {
      if (isAuthenticated && !profile?.id) {
        await fetchUserProfile()
            .then((userProfile) => {
              dispatch(setUserProfile(userProfile));
            })
            .catch((error) => {
              console.error(error);
            });
      }
    }
    // if (!isLoading && !isAuthenticated) {
    //   navigate("/");
    // }
    checkProfile();
  }, [isLoading, isAuthenticated]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
          <Root />
      ),
    },
    {
      path: "/how-it-works",
      element: (
          <HowItWorks />
      ),
    },
    {
      path: "/for-talent",
      element: (
          <HowItWorks />
      ),
    },
    {
      path: "/for-marketers",
      element: (
          <HowItWorksMarketers />
      ),
    },
    {
      path: "/check-license/:id",
      element: (
        <div className="page_container">
          <AppHeader />
          <main>
            <LicenseCheckRoot />,
            <AppFooter />
          </main>
        </div>
      ),
    },
    {
      path: "/check-license",
      element: (
          <div className="page_container">
            <PublicHeader />
            <div className="page_content">
          <LicenseCheckRoot />,
            </div>
          </div>
      ),
    },
    {
      path: "/lp/:id",
      element: (
        <AppContainer>
          <LicensablePropertyProfile />
        </AppContainer>
      ),
    },
    {
      path: "/lp/:id/edit",
      element: (
        <AppContainer minimalHeader noFooter isEditing>
          <ArtistWizard isEditing />
        </AppContainer>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AppContainer>
          <Dashboard />
        </AppContainer>
      ),
    },
    {
      path: "/register",
      element: (
          <main className="page_container home_page bg-white">
            <PublicHeader theme="dark"/>
            <div className="home_container">
              <Register heading="Get started"/>
            </div>
            <AppFooter/>

            <script
                src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6541f3a0f6480d2cb5573c56"
                type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
                crossOrigin="anonymous"></script>

            <script src="https://assets-global.website-files.com/6541f3a0f6480d2cb5573c56/js/webflow.9b9a1d58f.js"
                    type="text/javascript"></script>
          </main>
),
},
{
  path: "/register/complete",
      element
:
  (
      <div className="page_container">
        <PublicHeader />
          <div className="page_content">
            <RegisterComplete />
          </div>
        </div>
      ),
    },
    {
      path: "/profile",
      element: (
        <AppContainer>
          <Register heading="Profile" />
        </AppContainer>
      ),
    },
    {
      path: "/settings",
      element: (
        <AppContainer>
          <div>Settings maybe?</div>
        </AppContainer>
      ),
    },
    {
      path: "/project",
      element: (
        <AppContainer>
          <ProjectsRouter />
        </AppContainer>
      ),
    },
    {
      path: "/project/new",
      element: (
        <AppContainer minimalHeader noFooter>
          <ProjectWizard />
        </AppContainer>
      ),
    },
    {
      path: "/project/:id",
      element: (
        <AppContainer>
          <Project />
        </AppContainer>
      ),
    },
    {
      path: "/project/:id/description",
      element: (
        <AppContainer minimalHeader>
          <ProjectDescription />
        </AppContainer>
      )
    },
    {
      path: "/project/:id/asset/:fileId",
      element: (
        <AppContainer>
          <ProjectAssetReview />
        </AppContainer>
      ),
    },
    {
      path: "/project/:id/edit",
      element: (
        <AppContainer minimalHeader noFooter>
          <ProjectWizard />
        </AppContainer>
      ),
    },
    {
      path: "/add-talent",
      element: (
        <AppContainer minimalHeader noFooter>
          <ArtistWizard />
        </AppContainer>
      ),
    },
    {
      path: "/login",
      element: <div>login</div>,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/license-terms/:id/:licenseType",
      element: <LicenseTerms />,
    }
  ]);

  return <RouterProvider router={router} />;
}

export default PageRouter;
