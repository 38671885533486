import React, { useCallback } from "react";
import WizardSetup from "../../../common/components/wizard_panel";
import FileManager from "../../../common/components/ui/file_manager";
import { UploadedFile } from "../../../api/file_api";
import { uniqueId } from "lodash";

export const ArtistAddTrainingData = ({ form, field, onUploadComplete }: { form: any; field: string; onUploadComplete: (idx: any) => void }) => {
    const uploadedFiles = form.watch("files")?.filter((file: UploadedFile) => file.usage === "training");
    const objectId = form.watch("id") ?? uniqueId();
    const currentFieldValue = form.watch(field) ?? null;

    const onFileUpload = useCallback((uploadedFiles: UploadedFile[]) => {
        const existingFiles = form.getValues("files");
        uploadedFiles.forEach((it) => {
            it.file_id = it.id;
            it.usage = "training";
        });
        if (existingFiles != null) {
            form.setValue("files", [...existingFiles, ...uploadedFiles]);
        } else {
            form.setValue("files", uploadedFiles);
        }
        onUploadComplete(7);
    }, [form]);

    const onFileChange = useCallback(
        (files: File[]) => {
            if (files) {
                form.setValue("files", files);
            }
        },
        [form, uploadedFiles]
    );

    const onFileRemove = (file: any) => {
        form.setValue(
            "files",
            uploadedFiles.filter((f: UploadedFile) => f.name !== file.name)
        );
    };

    return (
        <WizardSetup>
            <p className="w-[872px] pb-[38px]">
                This training data will be used to generate AI content that aligns with your unique identity, empowering you to maintain control over your digital representation. File Types Supported: Audio: .wav, .mp3, .aiff, Images: .jpg, .png, .tiff, Video: .mp4, .mov, .avi
            </p>
            <div
                style={{ backgroundImage: `url(${currentFieldValue})`, backgroundPosition: "center", backgroundSize: "cover" }}
                className="flex -col gap-10 text-center text-brand-black items-center  rounded-xl mb-10 w-[872px] h-[420px]"
            >
                <FileManager
                    uploadedFiles={uploadedFiles}
                    files={uploadedFiles}
                    multiple={true}
                    objectType="artist"
                    objectId={objectId}
                    uploadOnSelect={true}
                    onUploadedFilesChange={onFileUpload}
                    onFileChange={onFileChange}
                    onFileRemove={onFileRemove}
                />
            </div>
        </WizardSetup>
    )
}