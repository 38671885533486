import React, { useMemo } from "react";
import {
    TrashIcon,
    PhotoIcon,
    FileIcon,
    VideoIcon,
    AudioIcon
} from "../../common/icons/icons";
import { Button } from "./ui/button";
import bit_1 from "../bits/bit_1.svg";
import bit_2 from "../bits/bit_2.svg";
import bit_3 from "../bits/bit_3.svg";
import bit_4 from "../bits/bit_4.svg";

interface TrainingDataAssetReviewProps {
    asset: any;
    openDescriptionModal: () => void;
    deleteAsset: () => void;
}

const fileIcons = {
    image: <PhotoIcon />,
    video: <VideoIcon />,
    audio: <AudioIcon />,
    document: <FileIcon />,
};

const bitImages = [bit_1, bit_2, bit_3, bit_4];

const getRandomIndex = () => {
    return Math.floor(Math.random() * bitImages.length);
}

export const TrainingDataAssetReview = ({ asset, deleteAsset, openDescriptionModal }: TrainingDataAssetReviewProps) => {
    const { image, fileIcon } = useMemo(() => {
        let img, icon;
        switch (asset.file_type) {
            case "image":
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src={asset.permalink} alt="" />;
                icon = fileIcons.image;
                break;
            case "video":
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src={bitImages[getRandomIndex()]} alt="" />;
                icon = fileIcons.video;
                break;
            case "audio":
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src={bitImages[getRandomIndex()]} alt="" />;
                icon = fileIcons.audio;
                break;
            case "unknown":
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src={bitImages[getRandomIndex()]} alt="" />;
                icon = fileIcons.document;
                break;
            case "document":
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src="/images/sign_document_icon_white_110x80.png" alt="" />;
                icon = fileIcons.document;
                break;
            default:
                img = <img className="h-[60px] md:h-[80px] w-[109px] rounded-xl" src={bitImages[getRandomIndex()]} alt="" />;
                icon = fileIcons.document;
                break;
        }
        return { image: img, fileIcon: icon };
    }, [asset.file_type]);

    return (
        <div className="bg-gray-100 rounded-xl p-5 gap-10 flex min-w-[792px] justify-between">
            <div className="flex gap-10">
                {image}
                <div className="content-center">
                    <div className="flex">
                        {fileIcon}
                        <div className="text-xxs text-primary-dark-gray p-2">
                            {asset.file_type?.toUpperCase()}
                        </div>
                    </div>
                    <div className="italic text-xl text-primary-dark-gray">
                        {asset.name}
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="cursor-pointer font-semibold content-center" onClick={openDescriptionModal}>
                    {asset.description ? "View description" : "Add description"}
                </div>
                <Button onClick={deleteAsset}>
                    <TrashIcon />
                </Button>
            </div>
        </div>
    )
}